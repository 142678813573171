@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  @keyframes ping-slow {
    100% {
      transform: scale(1.5);
      opacity: 0;
    }
  }

  @keyframes toast-in {
    0% {
      opacity: 0;
      transform: translateY(20px) scale(0.9);
    }
    100% {
      opacity: 1;
      transform: translateY(0) scale(1);
    }
  }

  @keyframes toast-out {
    0% {
      opacity: 1;
      transform: translateY(0) scale(1);
    }
    100% {
      opacity: 0;
      transform: translateY(-20px) scale(0.9);
    }
  }
}

.react-datepicker-popper {
  z-index: 10000 !important;
}

.react-datepicker {
  z-index: 10000 !important;
}
