.sticky-header tr {
  position: sticky;
  top: 0;
  background-color: white;
}

.row-1 {
  position: sticky;
  top: -2px !important;
  background-color: white;
  z-index: 8;
}

.row-2 {
  position: sticky;
  top: 50px !important;
  background-color: white;
  z-index: 8;
}

.sticky-column {
  position: sticky;
  left: 0;
  background-color: white;
  z-index: 1;
}
