.react-datetime-pickers .react-datetime-pickers-overlay {
  right: 0 !important;
}

.react-datetime-pickers-input {
  font-size: 0.875rem;
  border-radius: 5px;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0;
  text-align: center;
  padding-right: 0;
}
