/* Heading 1 in Editor */
.ck-content h2 {
  font-size: 24px;
  font-weight: bolder;
}

/* Heading 2 in Editor */
.ck-content h3 {
  font-size: 20px;
  font-weight: bolder;
}

/* Heading 3 in Editor */
.ck-content h4 {
  font-size: 18px;
  font-weight: bolder;
}

.ck-content ul {
  list-style: disc inside;
}

.ck-content ol {
  list-style: decimal inside;
}

.ck-content a {
  font-weight: 700;
  text-decoration: underline;
}

.ck-content a:hover {
  font-weight: bolder;
  color: black;
}

.ck-content blockquote {
  border-left: 5px solid #ccc;
  font-style: italic;
  margin-left: 0;
  margin-right: 0;
  overflow: hidden;
  padding-left: 1.5em;
  padding-right: 1.5em;
}
